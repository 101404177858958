import React from "react"
import { Link } from "react-router-dom"
import Block6633 from "../../../../shared/components/Block6633"
import SectionHeader from "../../../../shared/components/SectionHeader"
import TextBlock from "../../../../shared/components/TextBlock"
import texts from "./texts.json"
import { scrollToTop } from "../../../../shared/helpers"
import "./styles.css"

import profilePic from "../../../../assets/johan.png"
import companyPic from "../../../../assets/logo-text-grey.png"

const { p1, link } = texts

const imgProfilePic = <img className="profile-pic" src={profilePic} alt="Johan" />
const imgCompanyPic = <img className="company-pic" src={companyPic} alt="company logo" />

const p1content = (
  <div className="center-y">
    <div>
      <p>{p1.description}</p>
      <div className="container-fluid space-top-md"></div>
      <p><Link onClick={() => scrollToTop()} to="/OptIn">{link.linkText}</Link>{link.text}</p>
    </div>
  </div>
)

const OptIn = () => {
  return (
    <div className="optin border-top container-fluid space-top-md">
      <SectionHeader header={p1.title} />
      <TextBlock text={p1content} />
    </div>
  );
}

export default OptIn